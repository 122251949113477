<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ selectedItem ? "Editar" : "Nueva" }} zona de delivery
        </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          :loading="loading"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre de zona</p>
              <v-text-field
                :loading="loading"
                v-model="zone.name"
                rounded
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col v-if="showMap" cols="12" sm="12" md="12">
              <map-c
                :latitude="location[0]"
                :longitude="location[1]"
                @updated="locationUpdated"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  :loading="loading"
                  v-model="zone.active"
                  label="Zona activa"
                >
                </v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  :loading="loading"
                  class="save-btn mt-4"
                  @click="selectedItem ? edit() : save()"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import mapC from "@/components/map-picker";
import { mapState } from "vuex";

export default {
  name: "add-delivery-zone",
  props: ["city", "selectedItem"],
  components: {
    mapC,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: false,
      zone: {
        active: true,
        name: "",
        location: [],
        deleted: false,
        createdAt: new Date(),
      },
      showMap: false,
      location: ["14.0923212", "-87.1913825"],
    };
  },

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    locationUpdated(e) {
      this.location = e;
    },

    save() {
      if (!this.zone.name) {
        this.snackbarText = "Debe ingresar el nombre de la zona.";
        this.snackbar = true;
        return;
      }

      this.loading = true;
      this.zone.createdBy = this.user[".key"];
      this.zone.location = new fb.firestore.GeoPoint(
        this.location[0],
        this.location[1]
      );

      db.collection("cities")
        .doc(this.city)
        .collection("deliveryZones")
        .add(this.zone)
        .then((response) => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },

    edit() {
      if (!this.zone.name) {
        this.snackbarText = "Debe ingresar el nombre de la zona.";
        this.snackbar = true;
        return;
      }

      this.loading = true;

      db.collection("cities")
        .doc(this.city)
        .collection("deliveryZones")
        .doc(this.selectedItem[".key"])
        .update({
          name: this.zone.name,
          location: new fb.firestore.GeoPoint(
            this.location[0],
            this.location[1]
          ),
          active: this.zone.active,
          modifiedBy: this.user[".key"],
          modifiedAt: new Date(),
        })
        .then((response) => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },

  async mounted() {
    if (this.selectedItem) {
      this.zone = Object.assign({}, this.selectedItem);
      this.location = [
        this.zone.location.latitude,
        this.zone.location.longitude,
      ];
    }

    this.showMap = true;
  },
};
</script>

