var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"11","md":"11"}},[_c('h1',[_vm._v("Zonas de Delivery")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"mx-8",staticStyle:{"width":"90%"},attrs:{"items":_vm.countries,"placeholder":"Escoge el pais","outlined":"","item-text":"name","item-value":".key","rounded":""},on:{"change":_vm.getCities},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"mx-8",staticStyle:{"width":"90%"},attrs:{"items":_vm.cities,"placeholder":"Escoge la ciudad","outlined":"","disabled":!_vm.selectedCountry,"item-text":"name","item-value":".key","rounded":""},on:{"change":_vm.getZones},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","disabled":!_vm.selectedCity,"loading":_vm.loading},on:{"click":function($event){return _vm.addZone()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Crear nueva zona ")],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.zones,"items-per-page":15,"search":_vm.$store.state.search,"loading":_vm.loading,"item-key":".key","sort-by":['position'],"sort-desc":[false, true],"single-expand":true,"fixed-header":"","height":"75vh","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { active: item.active },
                item['.key'],
                item
              )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.location`,fn:function({ item }){return [_c('a',{staticStyle:{"display":"block"},attrs:{"href":'https://www.google.com/maps/search/?api=1&query=' +
              item.location.latitude +
              ',' +
              item.location.longitude,"target":"_blank"}},[_vm._v(" "+_vm._s(item.location.latitude ? parseFloat(item.location.latitude).toFixed(6) + "," : "no disponible")+_vm._s(item.location.longitude ? parseFloat(item.location.longitude).toFixed(6) : "")+" "),_c('i',{staticClass:"ml-1 fas fa-external-link-alt"})])]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editZone(item)}}},[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"ml-3 white--text",attrs:{"small":"","color":"red darken-4"},on:{"click":function($event){return _vm.deleteZone(item)}}},[_vm._v(" Eliminar ")])]}}],null,true)})],1)],1),(_vm.addZoneDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.addZoneDialog),callback:function ($$v) {_vm.addZoneDialog=$$v},expression:"addZoneDialog"}},[_c('add-zone',{attrs:{"selectedItem":_vm.selectedItem,"city":_vm.selectedCity},on:{"cancel":function($event){_vm.addZoneDialog = false},"success":_vm.handleCreate}})],1):_vm._e(),(_vm.deleteDialog)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar acción "),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_vm._v(" ¿Está seguro que desea eliminar "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedItem.name))]),_vm._v("? ")]),_c('v-card-actions',{staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"border-radius":"50px"},attrs:{"color":"red darken-4","width":"90%","dark":"","min-height":"47"},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }