<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Zonas de Delivery</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getCities"
          style="width: 90%"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCity"
          :items="cities"
          style="width: 90%"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          @change="getZones"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCity"
            @click="addZone()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva zona
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="zones"
          :items-per-page="15"
          :search="$store.state.search"
          :loading="loading"
          item-key=".key"
          :sort-by="['position']"
          :sort-desc="[false, true]"
          :single-expand="true"
          fixed-header
          height="75vh"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.location`]="{ item }">
            <a
              style="display: block"
              :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                item.location.latitude +
                ',' +
                item.location.longitude
              "
              target="_blank"
            >
              {{
                item.location.latitude
                  ? parseFloat(item.location.latitude).toFixed(6) + ","
                  : "no disponible"
              }}{{
                item.location.longitude
                  ? parseFloat(item.location.longitude).toFixed(6)
                  : ""
              }}
              <i class="ml-1 fas fa-external-link-alt"></i>
            </a>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editZone(item)" small color="primary">
              Editar
            </v-btn>

            <v-btn
              @click="deleteZone(item)"
              small
              color="red darken-4"
              class="ml-3 white--text"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-if="addZoneDialog"
      v-model="addZoneDialog"
      max-width="60%"
    >
      <add-zone
        @cancel="addZoneDialog = false"
        @success="handleCreate"
        :selectedItem="selectedItem"
        :city="selectedCity"
      >
      </add-zone>
    </v-dialog>

    <v-dialog max-width="400" v-model="deleteDialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="deleteDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ selectedItem.name }}</span
          >?
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            min-height="47"
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";

import addZone from "./add-zone";

export default {
  name: "categories",
  components: {
    lottie: Lottie,
    addZone,
  },
  data() {
    return {
      loading: true,
      saving: false,
      countries: [],
      snackbar: false,
      snackbarText: "",
      deleteDialog: false,
      selectedCity: "",
      selectedCountry: "",
      addZoneDialog: false,
      selectedItem: "",
      cities: [],
      zones: [],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,

      headers: [
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },

        {
          text: "Coordenadas",
          value: "location",
          class: "nowrap",
        },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          value: "options",
          align: "end",
          width: "500px",
        },
      ],
    };
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    handleCreate() {
      this.snackbarText = this.selectedItem
        ? "Zona editada correctamente."
        : "Zona agregada correctamente.";
      this.snackbar = true;
      this.addZoneDialog = false;
    },

    getCities(countryId) {
      this.categories = [];
      this.cities = [];
      this.selectedCity = "";

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });
        });
    },

    async getZones() {
      this.loading = true;
      await this.$binding(
        "zones",
        db
          .collection("cities")
          .doc(this.selectedCity)
          .collection("deliveryZones")
          .where("deleted", "==", false)
      );
      this.loading = false;
    },

    addZone() {
      this.selectedItem = null;
      this.addZoneDialog = true;
    },

    editZone(item) {
      this.selectedItem = item;
      this.addZoneDialog = true;
    },

    deleteZone(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    confirmDelete() {
      if (this.selectedItem) {
        db.collection("cities")
          .doc(this.selectedCity)
          .collection("deliveryZones")
          .doc(this.selectedItem[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.deleteDialog = false;
            this.snackbar = true;
            this.snackbarText = "Zona eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, zoneId, zone) {
      if (data && zoneId) {
        this.loading = true;
        db.collection("cities")
          .doc(this.selectedCity)
          .collection("deliveryZones")
          .doc(zoneId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (data.hasOwnProperty("active")) {
              zone.active = !zone.active;
            }
          });
      }
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "zona");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    db.collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
        this.loading = false;
      });
  },
};
</script>
