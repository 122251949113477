<template>
  <v-row>
    <v-col v-if="!loading" cols="12" sm="12" md="12">
      <l-map
        @update:center="centerUpdated"
        class="fill-height"
        style="height: 50vh"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker
          :lat-lng="[center[0], center[1]]"
          @update:lat-lng="positionUpdated"
          :draggable="true"
          :icon="getImage"
        >
          <l-tooltip>Mueva el marcador a la nueva ubicación</l-tooltip>
        </l-marker>
        <v-geosearch :options="geosearchOptions"></v-geosearch>
      </l-map>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <h2>Latitud</h2>
      <input
        class="control-input control-input-number"
        placeholder="Latitude"
        v-model="center[0]"
      />
      <!-- <v-text-field
        filled
        rounded
        clearable
        hide-details
        placeholder="Latitud"
        v-model.number="center[0]"
      /> -->
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <h2>Longitud</h2>
      <input
        class="control-input control-input-number"
        placeholder="Longitud"
        v-model="center[1]"
      />
      <!-- <v-text-field
        filled
        rounded
        clearable
        hide-details
        placeholder="Longitud"
        v-model.number="center[1]"
      /> -->
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

export default {
  name: "map-c",
  props: ["longitude", "latitude", "name"],
  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    LTooltip,
    VGeosearch,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      center: [14.0607226, -87.2214454],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      snackbarText: "",
      loading: true,
      saving: false,
    };
  },
  watch: {
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 2;
        }, 100);
      }
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/user.svg"),
        shadowUrl: require("@/assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [2, 59],
        popupAnchor: [0, -76],
      });
    },
  },
  methods: {
    positionUpdated(e) {
      this.center = [e.lat, e.lng];
      this.$emit("updated", this.center);
    },
    centerUpdated(e) {
      this.center = [e.lat, e.lng];
      this.$emit("updated", this.center);
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    success() {
      if (this.center[0] && this.center[1]) {
        this.saving = true;
        this.$emit("success", this.center);
      } else {
        this.snackbarText = "Ubicación no válida.";
        this.snackbar = true;
      }
    },
    handleError() {
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente.";
      this.snackbar = true;
      this.saving = false;
    },
  },
  mounted() {
    if (this.latitude && this.longitude) {
      this.center = [this.latitude, this.longitude];
    } else {
      this.center = ["14.0923212", "-87.1913825"];
    }
    this.zoom = 1;
    this.loading = false;
  },
};
</script>

<style>
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  /* background-color: #f96921; */
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.vue2leaflet-map {
  z-index: 1;
}
</style>